module.exports = [{
      plugin: require('/Users/radek/dev/cybertent-site/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://cybertent.co.uk"},
    },{
      plugin: require('/Users/radek/dev/cybertent-site/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('/Users/radek/dev/cybertent-site/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/radek/dev/cybertent-site/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cybertent Ltd.","short_name":"Cybertent","start_url":"/","background_color":"#ffffff","theme_color":"#3D6DD8","display":"minimal-ui","icon":"src/favicon.png","crossOrigin":"anonymous"},
    },{
      plugin: require('/Users/radek/dev/cybertent-site/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/Users/radek/dev/cybertent-site/node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"lang":"en","siteId":1683461,"cookiePolicyId":88708865,"cookiePolicyUrl":"https://cybertent.co.uk/cookies","banner":{"prependOnBody":false,"acceptButtonDisplay":true,"acceptButtonColor":"#448DF4","acceptButtonCaptionColor":"white","position":"bottom","textColor":"#676767","backgroundColor":"#ffffff","fontSizeBody":"11px"}},"googleTagManagerOptions":true},
    },{
      plugin: require('/Users/radek/dev/cybertent-site/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5WSPMBD","includeInDevelopment":true},
    },{
      plugin: require('/Users/radek/dev/cybertent-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
